<template>
  <v-container
      fill-height
      fluid
      grid-list-xl
  >
    <v-layout
        justify-center
        wrap
    >
      <data-table ref="orgPlanDataTable"
                  :key="'ManageOrgPlans'"
                  :api-end-point="apiurl"
                  :search="search"
                  :is-custom-filter="false"
                  :sort-order="sortOrder"
                  :table-name="'Manage Org Plans'"
                  :user_id="user_id"
                  :user_type="user_type"
                  :isDetail="true"
      >
        <template slot="datatable-actions">
          <div class="action-buttons ml-3">
            <button @click="showOrgPlanDialog" class="btn btn-primary">Add Organisation Plan</button>
          </div>
        </template>

      </data-table>

      <!-- delete dialog -->
      <confirmation v-if="deleteDialog" :visible="deleteDialog">
        <template slot="title">
          Are you sure?
        </template>
        <template slot="desc">
          This Org Plan will be removed. This action cannot be undone.
        </template>
        <template slot="actions">
          <a class="cancel-model" @click="hideConfirmation">Cancel</a>
          <button class="btn modal-action-button confirmation-delete" @click="removeOrgPlan">Delete</button>
        </template>
      </confirmation>
    </v-layout>
    <add-org-plan v-if="addOrgPlanDialog"
                  :org-plan="orgPlan"
                  :visible="addOrgPlanDialog"
                  v-on:hideAddOrgPlanModal="addOrgPlanDialog = false"
                  :action-type="actionType">

    </add-org-plan>
  </v-container>
</template>

<script>
import DataTable from "../../components/common/dataTable/DataTable";
import AddOrgPlan from "@/components/main/orgConsole/manageOrgPlans/addOrgPlan";
import Actions from "@/components/common/dataTable/users/Actions";
import Confirmation from "@/components/common/Confirmation";
export default {
  name: "manageOrgPlans",
  components: {
    Confirmation,
    Actions,
    AddOrgPlan,
    DataTable,
  },
  props: {
    user_id: {
      user_id: [Number, String]
    },
    user_type: {
      type: String
    },
  },
  data() {
    return {
      apiurl: "org-plans",
      search: {
        placeholder: "Search",
        searchAble: false
      },
      sortOrder: [
        {
          field: "id",
          sortField: "id",
          direction: "desc"
        }
      ],
      filters: [],
      selected_filters: [],
      addOrgPlanDialog: false,
      orgPlan: null,
      actionType: "",
      deleteDialog: false,
    }
  },
  events: {
    "open-details": function (data) {
      this.orgPlan = data;
      this.actionType = "edit";
      this.addOrgPlanDialog = true;

    },
    "delete-record": function (orgPlan) {
      this.deleteConfirmation(orgPlan.id);
    },
  },
  methods:{
    removeOrgPlan() {
      let loader = this.$loading.show();
      this.$http.delete(this.url + "org-plan", {
        params: {
          id: this.orgPlan,
          value: "deleted"
        }
      }).then(response => {
        this.hideConfirmation();
        this.$events.fire("vuetable:reload");
        loader.hide();
        this.$toastr("success", response.data.message, "");
      }).catch(error => {
        loader.hide();
        this.$toastr("error", error.response.data.message, "");
      });
    },
    showOrgPlanDialog() {
      this.addOrgPlanDialog = true;
      this.actionType = "add";
      this.orgPlan = null;
    },
    hideConfirmation() {
      this.deleteDialog = false;
    },
    deleteConfirmation(orgPlanId) {
      this.deleteDialog = true;
      this.orgPlan = orgPlanId;
    },
  }
}
</script>
